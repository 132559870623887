// Modules
import React from 'react';
import {NextSeo} from "next-seo";
import { useRouter } from 'next/router';
import Head from "next/head";

// Components
import CTA from '../elements/CTA';

// Constants
import {META_INFO} from "../constants/metaConstants";

// Styles
const pageStyles = {
    backgroundImage: 'url(\'/assets/images/error-bg.svg\')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: '100%',
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

export default function Custom404() {
    const router = useRouter();

    return (
        <>
            <Head>
                <meta content={META_INFO.NOT_FOUND_PAGE_TITLE} name="twitter:title"/>
                <meta content={META_INFO.NOT_FOUND_PAGE_URL} name="twitter:url"/>
            </Head>

            <NextSeo
                title={META_INFO.NOT_FOUND_PAGE_TITLE}
                canonical={META_INFO.NOT_FOUND_PAGE_URL}
                openGraph={{
                    title: META_INFO.NOT_FOUND_PAGE_TITLE,
                    url: META_INFO.NOT_FOUND_PAGE_URL
                }}
            />

            <main className="main flex--center--center background--primaryLighter" style={pageStyles}>
                <img className='cursor--pointer' src="/assets/icons/logo.svg" alt="" style={{ width: 80 }} onClick={() => {
                    router.push('/').then();
                }} />
                <h1 className="color--primary marginTop--l8">404 - Page Not Found</h1>
                <h2 className='color--primary marginTop--l2'>We're sorry, we seem to have lost this page, but we don't want to lose you.</h2>
                <CTA className="marginTop--l4" type="primary" text="Go to Home" onAction={() => {
                    router.replace('/').then();
                }} />
            </main>
        </>
    );
}
